$bold: 700;
$medium: 500;
$regular: 400;

@mixin sans {
    font-family: 'Helvetica', Helvetica, sans-serif;
}

@mixin roboto {
    font-family: 'Roboto', sans-serif;
    font-weight: $regular;
}

// font options
@mixin font-smoothing($value: antialiased) {
    @if $value == antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}
