@import "../../../styles/global";
@import "../../../styles/font";

.home {

    .home-header {
        width: auto;
        margin: 22px 40px 0;

        .app-container  {
            display: flex;
            margin-bottom: 37px;

            .loading-applications {
                position: relative;
                width: 100%;
                height: 44px;

                .loader {
                    position: absolute;
                    top: 16px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
    
            .app-item {
                flex-direction: column;
                margin: 0;
    
                .button {
                    padding: 0 20px;
                    height: 44px;
    
                    &.button-selected {
                        padding: 0 26px;
                    }
                }
            }
        }
    
        .filter-container {
            display: flex;

            .DateRangePicker {
                flex-direction: column;
                margin-right: 8px;
            }

            .dropdown {
                flex-direction: column;
                width: 255px;
                margin-right: 8px;
            }

            margin-bottom: 27px;
        }
    }

    .analytics-container {
        background-color: rgba($light, 0.75);
        padding: 40px;

        .loading-keyfigures {
            position: relative;
            width: 100%;
            height: 98px;
            border-radius: 10px;
            margin-bottom: 20px;

            .loader {
                position: absolute;
                top: 43px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .keyfigures {
            display: flex;
            background-color: transparent;

            .info-box {
                margin-right: 10px;
                margin-bottom: 20px;

                &:last-of-type {
                    margin-right: 0px;
                }
            }
        }

        .doughnuts {
            background-color: transparent !important;
            display: grid;    
            grid-template-columns: 50% 50%;

            .doughnut-holder {
                &:first-of-type {
                    margin-right: 10px;
                }

                &:last-of-type {
                    margin-left: 10px;
                }
            }
        }
    }

    h1 {
        @include sans;
        font-size: 24px;
        line-height: 32px;
        font-weight: $bold;
        color: $darker-brand;
        margin-top: 0px;
        margin-bottom: 26px;
    }

    .group-info-container {
        display: flex;
        margin-top: 28px;

        .info-box {
            margin: 0 5px;
            
            &:first-of-type {
                margin: 0;
                margin-right: 5px;
            }
            &:last-of-type {
                margin: 0;
                margin-left: 5px;
            }
            &:only-of-type{
                margin: 0;
            }
        }
    }
}
