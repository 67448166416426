@import "../../../styles/global";
@import "../../../styles/font";

.date-picker {
    
    .DayPickerKeyboardShortcuts_buttonReset { display:none; }

    .DayPickerNavigation_button__default {
        border: none !important;
        outline: none !important;
        top: 12px;
        background-color: transparent !important;
    }

    .grid-container {
        background-color: transparent !important; 
    }

    .DateInput {
        width: auto;
        background-color: transparent !important;
    }

    .DateRangePickerInput__withBorder {
        border-radius: 5px;
        border: 1px $light solid;
    }

    .DayPicker_focusRegion {
        background-color: transparent !important;
    }

    .DayPicker_transitionContainer__horizontal {
        border-radius: 5px;
    }

    .DateInput_input {
        @include roboto;
        color: $darker-brand;
        font-size: 14px;
        line-height: 16px;
        padding: 13px;
        border: none;
        width: 75px;
        background-color: transparent !important;
    }

    .DateInput_fang {
        display: none;
    }

    .DateRangePicker_picker {
        background-color: transparent !important;
        top: 48px !important;
        
        .DayPicker__withBorder {
            border: 1px $dark solid;
            border-radius: 5px;
            box-shadow: none;
            background-color: transparent;

            div {
                &:first-of-type {
                    background-color: transparent !important;
                }
            }

        }        
    }

    .DayPicker_weekHeader {
        top:58px;
    }

    .CalendarMonth_caption {
        color: $darker-brand;
        font-weight: $medium;
        line-height: 16px;
        font-size: 14px;
        padding: 19px 0 45px;
    }

    .DayPicker_weekHeader_ul {
        li {
            text-transform: uppercase;
            font-weight: $medium;
        }
    }

    .CalendarDay__default {
        border: none !important;
        font-size: 14px;
        padding: 0;
        color: $darker-brand;
    }

    .CalendarDay__selected_span {
        color: white; //text
        background-color: $brand;
    }

    .CalendarDay__blocked_out_of_range, 
    .CalendarDay__blocked_out_of_range:active, 
    .CalendarDay__blocked_out_of_range:hover {
        color: $grey; //text
    }
      
      // Will edit selected date or the endpoints of a range of dates
    .CalendarDay__selected {
        background-color: $dark-brand;
        color: white;
    }

    .CalendarDay__selected_span:active, 
    .CalendarDay__selected_span:hover {
        background-color: $dark-brand;
        outline: none;
        user-select: none;
    }
      
      // Will edit when hovered over. _span style also has this property
    .CalendarDay__selected:hover {
        color: white;
        background-color: $dark-brand;
    }
      
      // Will edit when the second date (end date) in a range of dates
      // is not yet selected. Edits the dates between your mouse and said date
    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
        background-color: $brand;
        color: white;
    }
}
