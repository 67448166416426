@import "../../../../styles/global";
@import "../../../../styles/font";

.session-analytics {

    background-color: transparent !important;
    
    .line-holder {
        margin-bottom: 21px;
    }

    .doughnuts {
        background-color: transparent !important;
        display: grid;    
        grid-template-columns: 50% 50%;

        .doughnut-holder {
            
            &:first-of-type {
                margin-right: 10px;
            }

            &:last-of-type {
                margin-left: 10px;
            }
        }
    }
}
