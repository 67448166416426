@import "../../styles/global";
@import "../../styles/font";

.root {
    width: 300px;

    h1 {
        @include sans;
        font-size: 24px;
        line-height: 32px;
        color: $darker-brand;
        text-align: left;
        margin: 0 0 6px;
    }

    .radio-button {
        margin: 8px 0 0;
        &:last-of-type {
            margin: 8px 0 28px;
        }
    }

    .field {
        margin: 22px 0 0;
        &:last-of-type {
            margin: 18px 0 28px;
        }
    }

    p {
        @include roboto;
        line-height: 24px;
        font-size: 14px;
        color: $darker-brand;
        margin: 0 0 22px;
        text-align: left;
    }

    .link {
        @include roboto;
        line-height: 20px;
        font-size: 13px;
        color: $dark;
        margin: 14px 0 0;
        text-align: center;
        user-select: none;

        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &:active {
            color: $darker;
        }
    }
}
