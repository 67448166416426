@import '../../../styles/global';

.loader {
    border: 2px solid rgba($brand, .4);
    border-top: 2px solid $brand;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;

    &.loader-button {
        border: 2px solid rgba($white, .1);
        border-top: 2px solid $white;
    }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
