@import '../../../styles/global';
@import '../../../styles/font';

.tab-layout {

    display: flex;

    .tab {
        flex-direction: column;
        cursor: pointer;

        p {
            @include roboto;
            font-weight: $medium;
            color: $grey;
            font-size: 16px;
            margin: 0 17px 14px;
            transition: .2s;
            user-select: none;
        }

        div {
            height: 0px;
            margin-top: 2px;
            width: 100%;
        }

        &.tab-selected {
            p {
                color: $brand;
            }

            div {
                height: 2px;
                margin-top: 0px;
                background-color: $brand;
            }

            &:hover {
                p {
                    color: $brand;
                }
            }
        }

        &:hover {
            p {
                color: $dark;
            }
        }

        &:active {
            p {
                color: $darker;
            }
        }
    }
}
