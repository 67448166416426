@import '../../../styles/global';
@import '../../../styles/font';

.radio-button {
    position: relative;
    width: 100%;
    height: 64px;
    border-width: 1px;
    border-style: solid;
    border-color: $light;
    cursor: pointer;

    .radio {
        position: absolute;
        left: 17px;
        top: 23px;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        border-color: $dark;
        border-width: 2px;
        border-style: solid;
        margin: 0;
    }

    .radio-text {
        @include roboto;
        position: absolute;
        display: inline;
        left: 45px;
        font-size: 15px;
        line-height: 18px;
        top: 23px;
        font-weight: $bold;
        margin: 0;
    }

    &:hover {
        border-color: $grey;
    }

    &.radio-button-selected {
        .radio {
            border-width: 6px;
            height: 6px;
            width: 6px;
            border-color: $brand;
        }

        border-color: $brand;
    }
}
