@import "../../../styles/global";
@import "../../../styles/font";

.button {
    align-items: center;
    
    width: 100%;
    height: 40px;

    background-color: $brand;
    border-radius: 5px;

    border-style: none;

    transition: .1s;

    @include roboto;
    font-size: 15px;
    font-weight: $medium;
    color: $white;
    line-height: 20px;

    margin: auto;

    user-select: none;
    outline: none;

    &:enabled {
        cursor: pointer;
    }

    &:active {
        background-color: $darker-brand;
    }

    &:disabled {
        background-color: $dark;
    }

    &.button-outline {
        background-color: $white;
        color: $dark;
        font-weight: $medium;
        font-size: 16px;
        line-height: 32px;
        border: 1px solid $grey;

        &:hover {
            color: $darker;
            border: 1px solid $darker;
        }
    }

    &.button-device-group {
        background-color: $white;
        color: $dark;
        font-weight: $medium;
        font-size: 16px;
        line-height: 32px;

        &:hover {
            color: $darker;
        }
    }

    &.button-outline-pag {
        background-color: $white;
        color: $brand;
        font-weight: $medium;
        font-size: 14px;
        line-height: 16px;
        border: 1px solid $light;

        &:disabled {
            color: $dark;

            &:hover {
                border: 1px solid $light;
            }
        }

        &:hover {
            border: 1px solid $brand;
        }
    }

    &.button-selected {
        border: 1px solid $brand;
        color: $brand;

        &:hover {
            color: $brand;
        }
    }

    &.button-number {
        background-color: transparent;
        color: $darker;
        border: none;
        font-size: 14px;
        line-height: 16px;

        &.button-selected {
            background-color: $brand;
            color: $white;
            border: none;

            &:hover {
                color: $white;
            }
        }

        &:disabled {
            &:hover {
                color: $darker;
            }
        }

        &:hover {
            color: $darker-brand;
        }
    }

    .content-div {
        background-color: transparent;
        svg {
            margin-right: 10px;
            transform: translateY(1px)
        }
    }
}
