@import "../../../../styles/global";
@import "../../../../styles/font";

.stop-analytics {

    background-color: transparent !important;
    
    .bar-holder {
        margin-bottom: 21px;
    }
}
