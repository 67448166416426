@import "../../../styles/global";
@import "../../../styles/font";

.menu-item {
    @include roboto;
    position: relative;
    height: 44px;
    width: 100%;
    color: $darker-brand;
    font-weight: $medium;
    display: flex;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;

    span {
        &:first-of-type {
            position: absolute;
            left: 0;
            top: 0;
            width: 0px;
            height: 100%;
            background-color: $white;
        }
        &:last-of-type {
            height: 18px;
            margin: auto 16px auto 20px;
            vertical-align: middle;
        }
    }

    g {
        fill: $dark;
    }

    &.menu-item-active {
        color: $brand;
        transition: .2s;

        span {
            &:first-of-type {
                background-color: $brand;
                width: 4px;
            }
        }

        g {
            fill: $brand;
            transition: .2s;
        }
    }

    &:hover {
        span {
            &:first-of-type {
                background-color: $brand;
                width: 4px;
                transition: .2s;
            }
        }
    }
}
