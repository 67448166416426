@import "../../../../styles/global";
@import "../../../../styles/font";

.info-box {
    position: relative;
    height: 98px;
    flex-grow: 1;
    flex-direction: column;
    border-radius: 10px;

    h6 {
        @include roboto;    
        position: absolute;
        color: $darker-brand;
        font-size: 26px;
        top: 18px;
        left: 17px;
        margin: 0;
    }

    p {
        @include roboto;    
        position: absolute;
        color: $dark;
        font-weight: $bold;
        font-size: 12px;
        bottom: 18px;
        left: 17px;
        margin: 0;
        text-transform: uppercase;
    }

    .loader {
        width: 100px;
        height: 100px;
    }
}
