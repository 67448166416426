@import "../../../styles/global";
@import "../../../styles/font";


.field {
    @include roboto;
    position: relative;

    .label {
        font-weight: $medium;
        text-align: left;
        font-size: 13px;
        line-height: 20px;
        margin: 0;
        color: $dark;
    }

    .input {
        width: 100%;

        line-height: 20px;
        font-size: 14px;

        margin: 6px 0 8px;

        border-style: none;
        border-color: transparent;
        outline: none;
        border: none;
        box-shadow: none;

        filter: none;

        &::-webkit-credentials-auto-fill-button {
            visibility: hidden;
        }
        &::-webkit-credentials-auto-fill-button {
            visibility: hidden;
        }
        &::-ms-clear {
            visibility: hidden;
        }
        &::-ms-reveal {
            visibility: hidden;
        }
    }

    .underline {
        height: 2px;
        width: 100%;
        background-color: $dark;
    }

    .error {
        width: 100%;
        margin: 8px 0 0;

        font-size: 13px;
        line-height: 24px;
    }

    .eye {
        position: absolute;
        right: 0;
        top: 30px;

        cursor: pointer;

        g {
            fill: $light;
        }

        &:hover {
            g {
                fill: $grey;
            }
        }

        &:active {
            g {
                fill: $dark;
            }
        }
    }

    &.field-focussed {
        .label {
            color: $brand;
        }

        .underline {
            background-color: $brand;
        }
    }

    &.field-error {
        .label {
            color: $error;
        }

        .underline {
            background-color: $error;
        }

        .error {
            color: $error;
        }
    }
}
