@import '../../../styles/global';
@import '../../../styles/font';

.check-box {
    position: relative;
    height: 14px;
    width: 14px;
    margin: 0px;
    border: 1px solid $dark;
    border-radius: 3px;
    background-color: $white;
    transition: .1s;
    user-select: none;

    cursor: pointer;

    .check {
        position: absolute;
        top: 3px;
        left: 3px;
    }

    &:hover {
        border: 1px solid $grey;
    }

    &:active {
        border: 1px solid $darker;
    }

    &.check-box-selected {
        border: 1px solid $brand;
        background-color: $brand;
    }
}
