@import "../../../../styles/global";
@import "../../../../styles/font";

.tour-analytics {

    background-color: transparent !important;
    
    .line-holder {
        margin-bottom: 21px;
    }
}
