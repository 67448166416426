@import "../../../../styles/global";
@import "../../../../styles/font";

.doughnut-holder {
    position: relative;
    border-radius: 10px;
    padding-bottom: 21px;

    header {
        position: relative;
        padding: 22px 17px 29px;

        h6 {
            @include sans;
            font-size: 20px;
            line-height: 32px;
            margin: 0;
            color: $darker-brand;
            font-weight: $bold;
        }

        .refresh-button {
            position: absolute;
            right: 30px;
            top: 30px;
            width: 16px;
            height: 16px;
            cursor: pointer;

            &:hover {
                g {
                    fill: $darker;
                }
            }

            &:active {
                g {
                    fill: $darker-brand;
                }
            }
        }
    }

    .graph {
        background-color: transparent !important;

        .loading-surface {
            position: relative;
            width: 100%;
            height: 312px;

            .loader {
                position: absolute;
                top: 150px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .graph-info-holder {
        position: absolute;
        top: 168px;
        width: 100%;
        background-color: transparent !important;
        pointer-events: none;

        h6 {
            @include roboto;
            font-size: 36px;
            line-height: 42px;
            margin: 0 0 7px;
            color: $darker-brand;
            text-align: center;
            pointer-events: none;
        }

        p {
            @include sans;
            font-size: 12px;
            line-height: 14px;
            margin: 0;
            color: $dark;
            text-transform: uppercase;
            text-align: center;
            font-weight: $bold;
            pointer-events: none;
        }
    }

    .graph-items {
        display: flex;
        grid-template-columns: auto auto auto auto;
        width: 70%;
        margin: 18px auto;
        justify-content: center;
        flex-wrap: wrap;

        .doughnut-item {
            width: 130px;
            display: flex;
            justify-content: center;
            
            .item-color {
                height: 12px;
                width: 12px;
                border-radius: 6px;
                background-color: $darker;
                margin: 12px;
                transition: .2s;
                
                &.selected-item-color {
                    height: 16px;
                    width: 16px;
                    margin: 10px;
                    border-radius: 8px;
                }
            }
    
            p {
                @include roboto;
                font-weight: $medium;
                margin: 0;
                color: $dark;
                display: inline;
                font-size: 13px;
                line-height: 36px;
    
                &:last-of-type {
                    color: $darker;
                    margin-left: 4px;
                }
            }
        }
    }
}
