@import "../../../styles/global";
@import "../../../styles/font";

.icon-button {
    border-radius: 5px;
    border: 1px solid $light;
    width: 32px;
    height: 32px;
    cursor: pointer;

    :only-child {
        margin: 9px;
    }

    &:hover {
        background-color: rgba($lighter, 0.5);

        g {
            fill: rgba($darker, 1);
        }
    }

    &:active {
        background-color: rgba($lighter, 1);

        g {
            fill: rgba($black, 0.6);
        }
    }
}
