@import "../../../../styles/global";
@import "../../../../styles/font";

.bar-holder {
    position: relative;
    border-radius: 10px;
    padding-bottom: 26px;

    header {
        position: relative;
        padding: 22px 17px 29px;

        h6 {
            @include sans;
            font-size: 20px;
            line-height: 32px;
            margin: 0;
            color: $darker-brand;
            font-weight: $bold;
        }

        .refresh-button {
            position: absolute;
            right: 30px;
            top: 30px;
            width: 16px;
            height: 16px;
            cursor: pointer;

            &:hover {
                g {
                    fill: $darker;
                }
            }

            &:active {
                g {
                    fill: $darker-brand;
                }
            }
        }
    }

    .graph {
        background-color: transparent !important;

        .loading-surface {
            position: relative;
            width: 100%;
            height: 312px;

            .loader {
                position: absolute;
                top: 150px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}
